/* font-family: 'Dela Gothic One', sans-serif;
font-family: 'Roboto', sans-serif; */

/* scrollbar */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
}


::-webkit-scrollbar-thumb {
    background: var(--black);
}


::-webkit-scrollbar-track {
    background: transparent;
}


/* fonts */

@font-face {
    font-family: "Neue Machina Light";
    src: url("../public/fonts/neue_machina/PPNeueMachina-InktrapLight.woff2");
}

@font-face {
    font-family: "Neue Machina Regular";
    src: url("../public/fonts/neue_machina/PPNeueMachina-InktrapRegular.woff2");
}

@font-face {
    font-family: "Neue Machina Bold";
    src: url("../public/fonts/neue_machina/PPNeueMachina-PlainUltrabold.woff2");
}

:root {
    --main-font: "Neue Machina Regular";
    --marquee-font: "Neue Machina Bold";
    --black: rgb(46, 46, 46);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


.overlay-livesoon {
    height: 100vh;
    width: 100vw;
    background-color: lime;
    font-family: var(--marquee-font);
    position: absolute;
    z-index: 10000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
}




.stream-video h1 {
    font-size: calc(1rem + 5vw);
    font-family: var(--marquee-font);
    text-align: center;
}

.overlay-livesoon h1 {
    font-size: calc(2rem + 4vw);
    text-transform: uppercase;
    color: var(--black);
    max-width: 1100px;
    text-align: center;
}

.cube-container {
    width: 3em;
    height: 3em;
    perspective: 55em;
    position: relative;
}

.cube {
    width: 50%;
    height: 50%;
    position: absolute;
    transform-style: preserve-3d;
    animation: rotateCube 25s linear infinite;
}

@keyframes rotateCube {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }

    100% {
        transform: rotateX(360deg) rotateY(360deg);
    }
}

.cube-side {
    position: absolute;
    width: 80%;
    height: 80%;
    background-color: lime;
    border: 3px solid var(--black);
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: calc(1rem + 4vw);
    border-radius: 10vw;

}

.cube-side:nth-child(1) {
    transform: rotateY(0deg) translateZ(8.5em);

}



.cube-side:nth-child(3) {
    transform: rotateY(180deg) translateZ(8.5em);
    visibility: hidden;
}




/* main styling */

body {
    max-height: 100vh;
    height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}


.span {
    display: inline-block;
}



.threecontainer {
    max-height: 100vh;
    width: 100vw;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: -100;
    overflow: hidden;
}


.splashscreen {
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    z-index: 10001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(1rem + 1vw);
    transform-style: preserve-3d;
    transition: transform 0.6s linear, opacity 0.6s linear;
    -ms--transition: transform 0.6s linear, backdrop-filter 0.5s linear;
    overflow: hidden;
}

.cube-container {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(1rem + 1vw);
    perspective: 1000px;
}

.splashscreen-cube {
    height: calc(3rem + 10vw);
    width: calc(3rem + 10vw);
    animation: rotateCube 2s ease-in-out 0s;
    transform-style: preserve-3d;
}

.cube-face {
    position: absolute;
    height: calc(3rem + 10vw);
    width: calc(3rem + 10vw);
    backface-visibility: hidden;
    color: black;
}



.front {

    border: 1px solid var(--black);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    color: var(--black);
    background-color: white;
}

.back {

    transform: rotateY(180deg);
    border: 1px solid var(--black);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background-image: url("../public/img/peace_favicon.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
}

.letterdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--marquee-font);
    font-size: calc(1rem + 2vw);
    height: 100%;
    width: 100%;
    border: 1px solid var(--black)
}

@keyframes rotateCube {
    0% {
        transform: rotateY(0deg);

    }


    100% {
        transform: rotateY(720deg);
    }
}


.splashscreen-cube:nth-of-type(1) {
    animation-delay: 0s;
}

.splashscreen-cube:nth-of-type(2) {
    animation-delay: 0.3s;
}

.splashscreen-cube:nth-of-type(3) {
    animation-delay: 0.6s;
}

.splashscreen-cube:nth-of-type(4) {
    animation-delay: 0.9s;
}

.splashscreen-cube:nth-of-type(5) {
    animation-delay: 1.2s;
}





.stream-video {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: space-between;

}

.twitch-wrapper {
    background-color: transparent;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.youtube-player {
    scale: 2;
    height: 100%;
    width: 100%;
    /* width: calc(10rem + 10vw);
    height: calc(10rem + 10vh); */
    display: flex;
    align-items: center;
    justify-content: center;

}

/* .stream-video iframe {
    scale: 1.5 !important;
    transform: translateY(40px);
} */

.image-container-landing {

    position: relative;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(100px);
    border-radius: calc(2rem + 6vw);
    scale: 0.2;
    border: 2px solid rgb(46, 46, 46);
    transition: background 0.2s linear;
}

.image-container-landing:hover {
    cursor: grab;
    background-color: lime;
}

.image-container-landing::before {
    content: "DONATE VIA TWINT";
    font-size: calc(1rem + 2vw);
    font-family: var(--marquee-font);
    position: absolute;
    height: 40%;
    width: 130%;
    border: 2px solid var(--black);
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(100px);
    text-align: center;
    transform: translateX(-50%);
    left: 50%;
    top: 105%;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: calc(30px + 5vw);
    transition: background 0.2s linear;
}

.image-container-landing:hover::before {
    background-color: lime;
}

.image-container-landing:nth-of-type(1)::before {
    content: "DONATE VIA TWINT";
}

.container-iban-landing::before {
    content: "CLICK TO COPY IBAN" !important;
}

.image-container-landing:active {
    cursor: grabbing;
}

.image-container-landing img {

    padding: 20px;
    max-height: 30vw;
    max-width: 30vw;
}

.iban-landing-img {
    scale: 0.7;
}

.iban-info-img {
    scale: 0.6;
}

.image-container-landing:nth-of-type(1) {
    transform: translateX(calc(-1*(10rem + 10vw)));
}

.image-container-landing:nth-of-type(2) {
    transform: translateX(calc(10rem + 10vw));
}

/* marquee */

#marquee-container {
    position: fixed;
    z-index: 10000;
    width: 100vw;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgb(46, 46, 46);
    height: calc(2rem + 3vh);
}

#marquee-content {
    z-index: 10000;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    gap: 20vw;
    padding-left: 100%;
    width: 100%;
    animation: marquee 60s linear infinite;
    color: white;
    font-family: var(--marquee-font), sans-serif;
    font-size: calc(1vw + 0.5rem);
    font-weight: 800;
    text-transform: uppercase;
}

.marquee-element {
    display: flex;
    align-items: center;
    gap: 20px;
}

.reddot {
    display: block;
    height: calc(0.6vw + 0.2rem);
    width: calc(0.6vw + 0.2rem);
    border-radius: 50%;
    transition: background-color 0.4s ease-in-out;
    animation: animateReddot 1.5s linear infinite;
}


.reddot-active {
    background-color: red;
}

.reddot-inactive {
    background-color: var(--black);
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-325%);
    }
}

@keyframes animateReddot {

    0% {
        scale: 0
    }

    70% {
        border-radius: 50%;
        rotate: 0deg;
    }

    80% {
        scale: 1;
        border-radius: 0%;
        rotate: 45deg;

    }

    100% {
        scale: 0;
        border-radius: 50%;
    }
}







/* footer */


.footer-button {
    border-radius: none !important;
    outline: none !important;
    border: 1px solid rgb(46, 46, 46);
    background-color: rgba(255, 255, 255, 0.4);
    font-family: var(--main-font);
    color: var(--black);
    backdrop-filter: blur(100px);
    width: calc(3rem + 7vw);
    height: calc(1rem + 2.5vh);
    font-size: calc(0.3rem + 0.4vw);
    text-transform: uppercase;
    transition: color 0.2s linear, background-color 0.2s linear, transform 0.1s linear, box-shadow 0.1s linear;
    border-radius: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    scale: 1.25;
}

.footer-button:hover {
    cursor: pointer;
    background-color: lime;
    color: rgb(46, 46, 46);
    transform: translateY(-0.2vh) translateX(-0.2vw);
    box-shadow: 2px 2px 1px rgb(46, 46, 46);
}

.footer-button:active {
    transform: translateY(0vh) translateX(0vw);
    box-shadow: 0px 0px 0px rgb(46, 46, 46);
}

.remove-background-button:hover {
    cursor: pointer;
    background-color: lime;
    color: rgb(46, 46, 46);
    transform: translateY(-0.2vh) translateX(0.2vw);
    box-shadow: -2px 2px 1px rgb(46, 46, 46);
}

.remove-background-button:active {
    transform: translateY(0vh) translateX(0vw);
    box-shadow: 0px 0px 0px rgb(46, 46, 46);
}


footer {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 3vw;
}

.footer-right {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;

}

footer p {
    font-family: var(--main-font), sans-serif;
    font-size: calc(0.35rem + 0.4vw);
    text-transform: uppercase;
}


/* overlay designs */

.overlay {
    position: absolute;
    top: calc(2rem + 3vh);
    left: 0;
    min-width: 100vw;
    height: calc(100vh - (2rem + 3vh));
    background-color: lime;
    z-index: 100;
    transform: translateY(100vh);
    transition: transform 0.3s linear, opacity 0.05s linear 0.6s;
    -moz-transition: transform 0.3s linear, opacity 0.05s linear 0.6s;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlay-visible {
    transform: translateY(0vh) !important;
    opacity: 1;
    transition: transform 0.3s linear, opacity 0.05s linear;
    -moz-transition: transform 0.3s linear, opacity 0.05s linear;
}

.overlay-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(1rem + 3vh);
    overflow-x: hidden;
    padding-top: 3vh;
    z-index: 101;
    padding-bottom: 5vh;
}

.overlay h1 {
    position: static !important;
    font-family: var(--marquee-font);
    color: var(--black);
    font-size: calc(2rem + 2.5vw);
    letter-spacing: calc(-1*(0.1rem + 0.2vw));
    text-transform: uppercase;
    max-width: 1200px;
    /* margin-top: 18rem; */
}

.overlay h2 {
    font-family: Arial, Helvetica, sans-serif;
    width: 75%;
    text-align: center;
    font-size: calc(0.8rem + 0.3vw);
    line-height: calc(1rem + 0.7vh);
    font-weight: 300;
    max-width: 1100px;

}

.overlay h2:nth-of-type(2) {
    transform: translateY(calc(1rem + 2vh));
}



.img-container {
    height: calc(5rem + 5vh);
    width: 50%;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
}

.img-container img {
    height: calc(2rem + 6vw);
    width: calc(2rem + 6vw);

}



.image-div-for-before {
    height: calc(2rem + 6vw);
    width: 100%;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: center;
    transition: transform 1s cubic-bezier(0.3, -0.5, 0.2, 1);
    -moz-transition: transform 1s cubic-bezier(0.3, -0.5, 0.2, 1);
}

.image-div-for-before img:hover {
    cursor: grab;
}

.image-div-for-before img:active {
    cursor: grabbing;
}

.image-div-for-before::before {
    position: absolute;
    z-index: 100;
    height: 100%;
    opacity: 1;
    width: 150%;
    transform: translateX(-50%) translateY(100%);
    left: 50%;
    z-index: -1;
    font-size: calc(0.6rem + 0.2vw);
    text-align: center;
    margin-top: 1vh;
    content: "click to copy";
    font-family: var(--main-font);
    transition: height 0.3s linear, opacity 0.3s linear;
    -moz-transition: height 0.3s linear, opacity 0.3s linear;
}

.image-div-for-before:nth-of-type(1)::before {

    content: "click for TWINT";
    font-family: var(--main-font);


}

.image-div-for-before:nth-of-type(2)::before {

    content: "copy IBAN";
    font-family: var(--main-font);

}

.overlay-anchor-container {

    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calc(1rem + 2vh) 0px;
    gap: calc(1rem + 2vw);
    transform: translateY(calc((1rem + 2vh)/2));

}

.topic-anchor {
    border: 1px solid var(--black);
    height: 100%;
    width: calc(8rem + 3vw);
    text-align: center;
    padding: 15px 0;
    text-decoration: none;
    color: var(--black);
    border-radius: 40px;
    font-size: calc(0.3rem + 0.8vw);
    font-family: var(--marquee-font);
    transition: background-color 0.1s linear, transform 0.2s ease-in-out, box-shadow 0.1s linear, scale 0.2s ease-in-out, color 0.2s ease-in-out;

}

.topic-anchor:hover {
    background-color: var(--black);
    transform: translateY(-0.2vh) translateX(-0.2vw);
    box-shadow: 2px 2px 1px rgb(42, 201, 66);
    scale: 1.03;
    color: lime;
}

.topic-anchor:active {
    transform: translateY(0vh) translateX(0vw);
    box-shadow: 0px 0px 0px rgb(46, 46, 46);
}

/* .image-div-for-before:nth-of-type(1):hover::before,
.image-div-for-before:nth-of-type(1):hover::active {
    opacity: 1;
    height: 100%;
}

.image-div-for-before:nth-of-type(2):hover::before,
.image-div-for-before:nth-of-type(2):hover::active {
    opacity: 1;
    height: 100%;
} */



.cross {
    height: calc(0.8rem + 1vw);
    width: calc(0.8rem + 1vw);
    position: fixed;
    right: calc(1rem + 2vw);
    top: calc(1rem + 2vw);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s cubic-bezier(0.3, -0.5, 0.7, 1.5), scale 0.2s linear;
    -moz-transition: transform 0.2s cubic-bezier(0.3, -0.5, 0.7, 1.5), scale 0.2s linear;
}

.cross:hover {
    cursor: pointer;
    transform: rotate(90deg);
    scale: 1.15;
}

.cross div {
    position: absolute;
    height: 100%;
    width: 0.2rem;
    background-color: var(--black);

}

.cross div:nth-of-type(1) {
    transform: rotate(45deg);
}

.cross div:nth-of-type(2) {
    transform: rotate(-45deg);
}



.image-left {
    transform: translateX(-100vw);
}

.image-right {
    transform: translateX(100vw);

}

.image-visible {
    transform: translateX(0vw) !important;
}

.copied-container {
    display: none;
    position: absolute;
    height: calc(2rem + 6vw);
    width: calc(2rem + 6vw);
}

.landing-copied-container {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: calc(2rem + 6vw) !important;
    height: 100%;
    width: 100%;
}

.iban-landing-copied,
twint-landing-copied {
    border-radius: calc(2rem + 6vw) !important;
}

.landing-copied-container p {
    height: 100%;
    width: 100%;
    font-size: calc(2rem + 2vw);
    text-wrap: wrap;
    border-radius: calc(2rem + 6vw) !important;
}

.copied-container-visible {
    display: block;
    border-radius: calc(2rem + 6vw) !important;
}


.copied-successfully {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: lime;
    text-align: center;
    font-family: var(--marquee-font);
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.4s linear, height 0.4s ease-in-out;
    -moz-transition: opacity 0.4s linear, height 0.4s ease-in-out;
    pointer-events: none;
    color: var(--black)
}

.copied-successfully-visible {
    opacity: 1;
    height: 100%;
}