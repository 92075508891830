::-webkit-scrollbar {
  background: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--black);
}

::-webkit-scrollbar-track {
  background: none;
}

@font-face {
  font-family: Neue Machina Light;
  src: url("PPNeueMachina-InktrapLight.34b19095.woff2");
}

@font-face {
  font-family: Neue Machina Regular;
  src: url("PPNeueMachina-InktrapRegular.bc785874.woff2");
}

@font-face {
  font-family: Neue Machina Bold;
  src: url("PPNeueMachina-PlainUltrabold.7d200016.woff2");
}

:root {
  --main-font: "Neue Machina Regular";
  --marquee-font: "Neue Machina Bold";
  --black: #2e2e2e;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.overlay-livesoon {
  font-family: var(--marquee-font);
  z-index: 10000000;
  background-color: #0f0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 3rem;
  display: flex;
  position: absolute;
}

.stream-video h1 {
  font-size: calc(1rem + 5vw);
  font-family: var(--marquee-font);
  text-align: center;
}

.overlay-livesoon h1 {
  text-transform: uppercase;
  color: var(--black);
  text-align: center;
  max-width: 1100px;
  font-size: calc(2rem + 4vw);
}

.cube-container {
  perspective: 55em;
  width: 3em;
  height: 3em;
  position: relative;
}

.cube {
  transform-style: preserve-3d;
  width: 50%;
  height: 50%;
  animation: 25s linear infinite rotateCube;
  position: absolute;
}

@keyframes rotateCube {
  0% {
    transform: rotateX(0)rotateY(0);
  }

  100% {
    transform: rotateX(360deg)rotateY(360deg);
  }
}

.cube-side {
  border: 3px solid var(--black);
  background-color: #0f0;
  border-radius: 10vw;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  font-size: calc(1rem + 4vw);
  display: flex;
  position: absolute;
}

.cube-side:first-child {
  transform: rotateY(0)translateZ(8.5em);
}

.cube-side:nth-child(3) {
  visibility: hidden;
  transform: rotateY(180deg)translateZ(8.5em);
}

body {
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.span {
  display: inline-block;
}

.threecontainer {
  z-index: -100;
  width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.splashscreen {
  z-index: 10001;
  transform-style: preserve-3d;
  -ms--transition: transform .6s linear, backdrop-filter .5s linear;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(1rem + 1vw);
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  transition: transform .6s linear, opacity .6s linear;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cube-container {
  z-index: 10001;
  perspective: 1000px;
  justify-content: center;
  align-items: center;
  gap: calc(1rem + 1vw);
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.splashscreen-cube {
  transform-style: preserve-3d;
  width: calc(3rem + 10vw);
  height: calc(3rem + 10vw);
  animation: 2s ease-in-out rotateCube;
}

.cube-face {
  backface-visibility: hidden;
  color: #000;
  width: calc(3rem + 10vw);
  height: calc(3rem + 10vw);
  position: absolute;
}

.front {
  border: 1px solid var(--black);
  color: var(--black);
  background-color: #fff;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.back {
  border: 1px solid var(--black);
  background-color: #fff;
  background-image: url("peace_favicon.e4109210.png");
  background-repeat: no-repeat;
  background-size: contain;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  display: grid;
  transform: rotateY(180deg);
}

.letterdiv {
  font-family: var(--marquee-font);
  border: 1px solid var(--black);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: calc(1rem + 2vw);
  display: flex;
}

@keyframes rotateCube {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(720deg);
  }
}

.splashscreen-cube:first-of-type {
  animation-delay: 0s;
}

.splashscreen-cube:nth-of-type(2) {
  animation-delay: .3s;
}

.splashscreen-cube:nth-of-type(3) {
  animation-delay: .6s;
}

.splashscreen-cube:nth-of-type(4) {
  animation-delay: .9s;
}

.splashscreen-cube:nth-of-type(5) {
  animation-delay: 1.2s;
}

.stream-video {
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.twitch-wrapper {
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
}

.youtube-player {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  scale: 2;
}

.image-container-landing {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  background-color: #fff9;
  border: 2px solid #2e2e2e;
  border-radius: calc(2rem + 6vw);
  transition: background .2s linear;
  position: relative;
  scale: .2;
}

.image-container-landing:hover {
  cursor: grab;
  background-color: #0f0;
}

.image-container-landing:before {
  content: "DONATE VIA TWINT";
  font-size: calc(1rem + 2vw);
  font-family: var(--marquee-font);
  border: 2px solid var(--black);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  text-align: center;
  background-color: #fff9;
  border-radius: calc(30px + 5vw);
  justify-content: center;
  align-items: center;
  width: 130%;
  height: 40%;
  padding: 18px;
  transition: background .2s linear;
  display: flex;
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
}

.image-container-landing:hover:before {
  background-color: #0f0;
}

.image-container-landing:first-of-type:before {
  content: "DONATE VIA TWINT";
}

.container-iban-landing:before {
  content: "CLICK TO COPY IBAN" !important;
}

.image-container-landing:active {
  cursor: grabbing;
}

.image-container-landing img {
  max-width: 30vw;
  max-height: 30vw;
  padding: 20px;
}

.iban-landing-img {
  scale: .7;
}

.iban-info-img {
  scale: .6;
}

.image-container-landing:first-of-type {
  transform: translateX(calc(-10rem - 10vw));
}

.image-container-landing:nth-of-type(2) {
  transform: translateX(calc(10rem + 10vw));
}

#marquee-container {
  z-index: 10000;
  white-space: nowrap;
  background-color: #2e2e2e;
  width: 100vw;
  height: calc(2rem + 3vh);
  position: fixed;
  overflow: hidden;
}

#marquee-content {
  z-index: 10000;
  color: #fff;
  font-family: var(--marquee-font), sans-serif;
  text-transform: uppercase;
  flex-direction: row;
  align-items: center;
  gap: 20vw;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  padding-left: 100%;
  font-size: calc(1vw + .5rem);
  font-weight: 800;
  animation: 60s linear infinite marquee;
  display: flex;
}

.marquee-element {
  align-items: center;
  gap: 20px;
  display: flex;
}

.reddot {
  border-radius: 50%;
  width: calc(.6vw + .2rem);
  height: calc(.6vw + .2rem);
  transition: background-color .4s ease-in-out;
  animation: 1.5s linear infinite animateReddot;
  display: block;
}

.reddot-active {
  background-color: red;
}

.reddot-inactive {
  background-color: var(--black);
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-325%);
  }
}

@keyframes animateReddot {
  0% {
    scale: 0;
  }

  70% {
    border-radius: 50%;
    rotate: none;
  }

  80% {
    border-radius: 0%;
    rotate: 45deg;
    scale: 1;
  }

  100% {
    border-radius: 50%;
    scale: 0;
  }
}

.footer-button {
  font-family: var(--main-font);
  color: var(--black);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  text-transform: uppercase;
  text-align: center;
  background-color: #fff6;
  border: 1px solid #2e2e2e;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  width: calc(3rem + 7vw);
  height: calc(1rem + 2.5vh);
  font-size: calc(.3rem + .4vw);
  font-weight: 800;
  transition: color .2s linear, background-color .2s linear, transform .1s linear, box-shadow .1s linear;
  display: flex;
  scale: 1.25;
  border-radius: none !important;
  outline: none !important;
}

.footer-button:hover {
  cursor: pointer;
  color: #2e2e2e;
  background-color: #0f0;
  transform: translateY(-.2vh)translateX(-.2vw);
  box-shadow: 2px 2px 1px #2e2e2e;
}

.footer-button:active {
  transform: translateY(0)translateX(0);
  box-shadow: 0 0 #2e2e2e;
}

.remove-background-button:hover {
  cursor: pointer;
  color: #2e2e2e;
  background-color: #0f0;
  transform: translateY(-.2vh)translateX(.2vw);
  box-shadow: -2px 2px 1px #2e2e2e;
}

.remove-background-button:active {
  transform: translateY(0)translateX(0);
  box-shadow: 0 0 #2e2e2e;
}

footer {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0 3vw;
  display: flex;
  position: absolute;
  bottom: 5vh;
}

.footer-right {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  display: flex;
}

footer p {
  font-family: var(--main-font), sans-serif;
  text-transform: uppercase;
  font-size: calc(.35rem + .4vw);
}

.overlay {
  z-index: 100;
  opacity: 0;
  background-color: #0f0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  height: calc(97vh - 2rem);
  transition: transform .3s linear, opacity 50ms linear .6s;
  display: flex;
  position: absolute;
  top: calc(2rem + 3vh);
  left: 0;
  transform: translateY(100vh);
}

.overlay-visible {
  opacity: 1;
  transition: transform .3s linear, opacity 50ms linear;
  transform: translateY(0) !important;
}

.overlay-container {
  z-index: 101;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(1rem + 3vh);
  width: 100vw;
  height: 100vh;
  padding-top: 3vh;
  padding-bottom: 5vh;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.overlay h1 {
  font-family: var(--marquee-font);
  color: var(--black);
  letter-spacing: calc(-.1rem - .2vw);
  text-transform: uppercase;
  max-width: 1200px;
  font-size: calc(2rem + 2.5vw);
  position: static !important;
}

.overlay h2 {
  text-align: center;
  width: 75%;
  max-width: 1100px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: calc(.8rem + .3vw);
  font-weight: 300;
  line-height: calc(1rem + .7vh);
}

.overlay h2:nth-of-type(2) {
  transform: translateY(calc(1rem + 2vh));
}

.img-container {
  justify-content: space-between;
  width: 50%;
  max-width: 1200px;
  height: calc(5rem + 5vh);
  display: flex;
}

.img-container img {
  width: calc(2rem + 6vw);
  height: calc(2rem + 6vw);
}

.image-div-for-before {
  background-color: #0000;
  justify-content: center;
  width: 100%;
  height: calc(2rem + 6vw);
  transition: transform 1s cubic-bezier(.3, -.5, .2, 1);
  display: flex;
  position: relative;
}

.image-div-for-before img:hover {
  cursor: grab;
}

.image-div-for-before img:active {
  cursor: grabbing;
}

.image-div-for-before:before {
  z-index: 100;
  opacity: 1;
  z-index: -1;
  text-align: center;
  content: "click to copy";
  font-size: calc(.6rem + .2vw);
  font-family: var(--main-font);
  width: 150%;
  height: 100%;
  margin-top: 1vh;
  transition: height .3s linear, opacity .3s linear;
  position: absolute;
  left: 50%;
  transform: translateX(-50%)translateY(100%);
}

.image-div-for-before:first-of-type:before {
  content: "click for TWINT";
  font-family: var(--main-font);
}

.image-div-for-before:nth-of-type(2):before {
  content: "copy IBAN";
  font-family: var(--main-font);
}

.overlay-anchor-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: calc(1rem + 2vw);
  width: 60%;
  padding: calc(1rem + 2vh) 0;
  display: flex;
  transform: translateY(calc(.5rem + 1vh));
}

.topic-anchor {
  border: 1px solid var(--black);
  text-align: center;
  color: var(--black);
  font-size: calc(.3rem + .8vw);
  font-family: var(--marquee-font);
  border-radius: 40px;
  width: calc(8rem + 3vw);
  height: 100%;
  padding: 15px 0;
  text-decoration: none;
  transition: background-color .1s linear, transform .2s ease-in-out, box-shadow .1s linear, scale .2s ease-in-out, color .2s ease-in-out;
}

.topic-anchor:hover {
  background-color: var(--black);
  color: #0f0;
  transform: translateY(-.2vh)translateX(-.2vw)scale3d(1.03, 1.03, 1);
  box-shadow: 2px 2px 1px #2ac942;
}

.topic-anchor:active {
  transform: translateY(0)translateX(0);
  box-shadow: 0 0 #2e2e2e;
}

.cross {
  justify-content: center;
  align-items: center;
  width: calc(.8rem + 1vw);
  height: calc(.8rem + 1vw);
  transition: transform .2s cubic-bezier(.3, -.5, .7, 1.5), scale .2s linear;
  display: flex;
  position: fixed;
  top: calc(1rem + 2vw);
  right: calc(1rem + 2vw);
}

.cross:hover {
  cursor: pointer;
  transform: rotate(90deg)scale3d(1.15, 1.15, 1);
}

.cross div {
  background-color: var(--black);
  width: .2rem;
  height: 100%;
  position: absolute;
}

.cross div:first-of-type {
  transform: rotate(45deg);
}

.cross div:nth-of-type(2) {
  transform: rotate(-45deg);
}

.image-left {
  transform: translateX(-100vw);
}

.image-right {
  transform: translateX(100vw);
}

.image-visible {
  transform: translateX(0) !important;
}

.copied-container {
  width: calc(2rem + 6vw);
  height: calc(2rem + 6vw);
  display: none;
  position: absolute;
}

.landing-copied-container {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: calc(2rem + 6vw) !important;
}

.iban-landing-copied, twint-landing-copied {
  border-radius: calc(2rem + 6vw) !important;
}

.landing-copied-container p {
  text-wrap: wrap;
  width: 100%;
  height: 100%;
  font-size: calc(2rem + 2vw);
  border-radius: calc(2rem + 6vw) !important;
}

.copied-container-visible {
  display: block;
  border-radius: calc(2rem + 6vw) !important;
}

.copied-successfully {
  text-align: center;
  font-family: var(--marquee-font);
  text-transform: uppercase;
  opacity: 0;
  pointer-events: none;
  color: var(--black);
  background-color: #0f0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .4s linear, height .4s ease-in-out;
  display: flex;
  position: absolute;
}

.copied-successfully-visible {
  opacity: 1;
  height: 100%;
}

/*# sourceMappingURL=index.0e2c4db6.css.map */
